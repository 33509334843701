import { useContext, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import GitHubIcon from '@material-ui/icons/GitHub'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'

import logoImage from './logo.png'

import { AuthContext } from '../contexts/authContext'

const APIGW_ENDPOINT = "https://o7d8uxd7kc.execute-api.us-east-1.amazonaws.com/prod/questions"

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    textAlign: 'center',
  },
  session: {
    width: '80vw',
    overflow: 'auto',
    overflowWrap: 'break-word',
    fontSize: '16px',
  },
  hero: {
    width: '100%',
    background: 'rgb(220,220,220)',
  },
  input:{
    // width: 200 vw;
  }
}))

export default function Home() {
  const classes = useStyles()

  const history = useHistory()

  const auth = useContext(AuthContext)

  function signOutClicked() {
    auth.signOut()
    history.push('/')
  }

  function changePasswordClicked() {
    history.push('changepassword')
  }

  const idTokenRegex = /CognitoIdentityServiceProvider\.([^\.]+)\.([^\.]+).idToken/;
  function getIdToken(): string | null {
    // Get all keys in local storage
    const keys = Object.keys(localStorage);

    // Find the key that matches the regular expression
    const idTokenKey = keys.find(key => key.match(idTokenRegex));

    // If a key was found, extract the `idToken` value from local storage
    if (!idTokenKey) {
      return null;
    }
    else {
      const idToken = localStorage.getItem(idTokenKey);
      return idToken;
    }
  }

  function NewSurveyQ(){

    const [question, setQuestion] = useState("");

    const handleSubmit = (event: { preventDefault: () => void }) =>{
      fetch(APIGW_ENDPOINT, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Authorization': getIdToken () as string,
        },
        body: JSON.stringify(question)
      });
    }

    return  (
    <pre className={classes.session}>
      <br/>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          variant="outlined"
          label={"New Question"}
          type="text"
          value={question}
          onChange = {(e) => setQuestion(e.target.value)}
        /><br/>
        <input type="submit" />
      </form>
    </pre>)
  }

  try {
    fetch(APIGW_ENDPOINT, {
    method: 'GET',
    // mode: 'cors',
    headers: {
      'Authorization': getIdToken () as string,
    }
  })
  .then((result: any) => result.text())
  .then((textformat: JSON) => {
    const Stuff = document.getElementById('apiData')
      if (Stuff){ 
          const data = JSON.parse(JSON.stringify(textformat, null, 2))
          Stuff.innerText = data.replaceAll("},{", "},\n{");
        }
      console.log(JSON.stringify(textformat, null, 2))
  })  
  } catch (error) {
    console.log(error)
  }
  
   
  return (
      <Grid container>
      <Grid className={classes.root} container direction="column" justify="center" alignItems="center">
        <Box className={classes.hero} p={4}>
          <Grid className={classes.root} container direction="column" justify="center" alignItems="center">
            <Box m={2}>
              <img src={logoImage} width={224} height={224} alt="logo" />
            </Box>
            <Box m={2}>
              <Link underline="none" color="inherit" href="https://github.com/dbroadhurst/aws-cognito-react">
                <Grid container direction="row" justify="center" alignItems="center">
                  <Box mr={3}>
                    <GitHubIcon fontSize="large" />
                  </Box>
                  <Typography className={classes.title} variant="h3">
                    OKTANK University
                  </Typography>
                </Grid>
              </Link>
            </Box>
            <Box m={2}>
              <Button onClick={signOutClicked} variant="contained" color="primary">
                Sign Out
              </Button>
            </Box>
            <Box m={2}>
              <Button onClick={changePasswordClicked} variant="contained" color="primary">
                Change Password
              </Button>
            </Box>
          </Grid>
        </Box>
        <Box m={2}>
          <NewSurveyQ />
        </Box>
        <Box m={2}>
          <Typography variant="h5">Questions Bank</Typography>
          <pre id="apiData" className={classes.session} style={{height: 400}}></pre>
        </Box>
      </Grid>
    </Grid>
  )
}
